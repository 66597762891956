<template>
    <div id="Index">
        <el-container>
            <el-header height='8vh'>
                <div class="logo_box" :style="{'width':logoWidth}">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"
                        class="logob">
                        <path fill="#c1c0ff"
                            d="M12,14.19531c-0.17551-0.00004-0.34793-0.04618-0.5-0.13379l-9-5.19726C2.02161,8.58794,1.85779,7.97612,2.13411,7.49773C2.22187,7.34579,2.34806,7.2196,2.5,7.13184l9-5.19336c0.30964-0.17774,0.69036-0.17774,1,0l9,5.19336c0.4784,0.27632,0.64221,0.88814,0.36589,1.36653C21.77813,8.65031,21.65194,8.7765,21.5,8.86426l-9,5.19726C12.34793,14.14913,12.17551,14.19527,12,14.19531z" />
                        <path fill="#a2a1ff"
                            d="M21.5,11.13184l-1.96411-1.13337L12.5,14.06152c-0.30947,0.17839-0.69053,0.17839-1,0L4.46411,9.99847L2.5,11.13184c-0.47839,0.27632-0.64221,0.88814-0.36589,1.36653C2.22187,12.65031,2.34806,12.7765,2.5,12.86426l9,5.19726c0.30947,0.17838,0.69053,0.17838,1,0l9-5.19726c0.4784-0.27632,0.64221-0.88814,0.36589-1.36653C21.77813,11.34579,21.65194,11.2196,21.5,11.13184z" />
                        <path fill="#6563ff"
                            d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152c-0.30947,0.17838-0.69053,0.17838-1,0l-7.03589-4.06305L2.5,15.13184c-0.47839,0.27632-0.64221,0.88814-0.36589,1.36653C2.22187,16.65031,2.34806,16.7765,2.5,16.86426l9,5.19726c0.30947,0.17838,0.69053,0.17838,1,0l9-5.19726c0.4784-0.27632,0.64221-0.88814,0.36589-1.36653C21.77813,15.34579,21.65194,15.2196,21.5,15.13184z" />
                    </svg>
                    <span slot="title" class="noSelect" v-show="!isColllapse">SHOUXD</span>
                </div>
                <div class="navigation_right" :style="{'width':logoWidth=='12%'? '88%':'97%'}">
                    <div class="breadcrumb_box">
                        <i class="el-icon-s-operation aside_btn" @click="toggleAside()"></i>
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <!-- <el-breadcrumb-item to="/home" class="noSelect" v-show="$route.name=='首页'">
                                {{$t('sidebar.home')}}
                            </el-breadcrumb-item> -->
                            <el-breadcrumb-item class="noSelect">
                                {{ $t(`sidebar.${$route.name}`)}}
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <!-- <div class="system_name">shuoxd</div> -->
                    <div class="headImg">
                        <el-dropdown placement="bottom" @command="handleCommand">
                            <div type="primary" class="languageSwitch noSelect">
                                {{$i18n.locale=='zh'? '中文':'English'}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="zh">中文</el-dropdown-item>
                                <el-dropdown-item command="en">English</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown trigger="click" size="medium" placement="bottom">
                            <div class="user">
                                <el-avatar icon="el-icon-user-solid" style="margin-right: 30px;"></el-avatar>
                                <span class="userName noSelect">{{ $store.state.login.userName }}</span>
                                <i class="el-icon-caret-bottom"></i>
                            </div>
                            <el-dropdown-menu slot="dropdown" :style="{ width: '10%', 'text-align': 'center' }">
                                <router-link to="/personal" style="text-decoration: none;">
                                    <el-dropdown-item class="noSelect">{{$t('sidebar.personal')}}</el-dropdown-item>
                                </router-link>
                                <el-dropdown-item @click.capture.native="logout()" class="noSelect">{{$t('sidebar.logout')}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </el-header>
            <el-container>
                <el-aside :width="aside_width" style="overflow: hidden;">
                    <el-menu class="el-menu-vertical-demo" :collapse="isColllapse" background-color="#fbfaff"
                        text-color="#545a6d" active-text-color="#2913e3" router :default-active="$route.path">
                        <el-menu-item-group>
                            <el-menu-item index="/home">
                                <svg t="1682231396942" class="Icon" viewBox="0 0 1033 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="3375" id="mx_n_1682231396943" width="20"
                                    height="20">
                                    <path
                                        d="M1002.599861 500.663121l-17.112402 17.112402c-4.889258 4.889258-12.223144 4.889258-17.124938 0l-36.669433-34.224803v339.891165c0 110.033371-90.47634 200.509711-200.509711 200.509711H278.814212c-112.478 2.444629-202.95434-88.031712-202.954341-198.065082V483.55072l-34.23734 34.224803c-4.889258 4.889258-12.223144 4.889258-17.112402 0l-17.112402-17.112402c-4.889258-4.889258-4.889258-12.223144 0-17.112401L489.102438 1.833472c2.444629-2.444629 9.778515-2.444629 14.667774 0 4.889258-2.444629 12.223144-2.444629 17.124938 0l481.704711 481.717248c4.889258 4.889258 4.889258 12.223144 0 17.112401zM403.515356 950.587658c0 9.778515 2.444629 17.124938 2.444629 24.458825H626.039263c2.444629-7.333887 2.444629-17.124938 2.444629-24.458825v-276.305742c0-56.239-44.015856-100.254856-100.254855-100.254856h-24.458825c-56.239 0-100.254856 44.015856-100.254856 100.254856v276.305742zM880.343346 434.645606L503.770212 58.085008 127.209614 434.645606v391.240908c0 83.142454 68.462144 151.604598 151.604598 151.604598h78.240659c-2.444629-7.333887-2.444629-17.112402-2.444629-24.446289V674.269379c0-83.142454 68.462144-151.604598 151.604598-151.604598h24.446289c83.142454 0 151.604598 68.462144 151.604598 151.604598v276.305742c0 9.778515 0 17.124938-2.444629 24.458825h53.794371c83.142454 0 151.604598-68.462144 151.604598-151.604598V434.645606h-4.876721z m0 0"
                                        p-id="3376" fill="#515151"></path>
                                </svg>
                                <span slot="title" class="noSelect">{{$t('sidebar.home')}}</span>
                            </el-menu-item>
                        </el-menu-item-group>
                        <el-submenu index="2">
                            <template slot="title">
                                <svg t="1679970338067" class="Icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="2846" width="20" height="20">
                                    <path
                                        d="M314.24 867.84h403.626667v21.333333H314.24zM864.853333 753.066667H165.333333a87.68 87.68 0 0 1-87.68-87.68V201.386667a87.68 87.68 0 0 1 87.68-87.68h699.52a87.68 87.68 0 0 1 87.68 87.68v464a87.68 87.68 0 0 1-87.68 87.68zM165.333333 135.04a66.346667 66.346667 0 0 0-66.346666 66.346667v464a66.346667 66.346667 0 0 0 66.346666 66.346666h699.52a66.346667 66.346667 0 0 0 66.346667-66.346666V201.386667a66.346667 66.346667 0 0 0-66.346667-66.346667z"
                                        fill="#515151" p-id="2847"></path>
                                    <path
                                        d="M504.32 742.4h21.333333v129.28h-21.333333zM679.253333 612.906667l-83.84-168.746667h-73.386666l-39.04 86.4-73.6-228.693333-126.506667 200.96-58.666667-58.666667H88.32v-21.333333h144.64l46.08 46.08 136.746667-217.173334 70.613333 219.52 21.76-48.426666h100.48l59.946667 120.746666 20.266666-126.72h252.8v21.333334h-234.666666l-27.733334 174.72z"
                                        fill="#515151" p-id="2848"></path>
                                </svg>
                                <span slot="title" class="noSelect">{{$t('sidebar.monitoring')}}</span>
                            </template>
                            <el-menu-item-group>
                                <!-- <template slot="title">设备列表</template> -->
                                <el-menu-item index="/endbenutzer">
                                    <span slot="title" class="noSelect">{{$t('sidebar.endbenutzer')}}</span>
                                </el-menu-item>
                                <el-menu-item index="/plant">
                                    <span slot="title" class="noSelect">{{$t('sidebar.plant')}}</span>
                                </el-menu-item>
                                <el-menu-item index="/datalog">
                                    <span slot="title" class="noSelect">{{$t('sidebar.datalog')}}</span>
                                </el-menu-item>
                                <el-menu-item index="/device">
                                    <span slot="title" class="noSelect">{{$t('sidebar.device')}}</span>
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index="3">
                            <template slot="title">
                                <svg t="1680486951747" class="Icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="2835" width="20" height="20">
                                    <path
                                        d="M153.088 806.4V583.68c0-15.36 12.288-27.648 27.648-27.648 15.36 0 27.648 12.288 27.648 27.648v223.232c0 15.36-12.288 27.648-27.648 27.648-15.36-0.512-27.648-12.8-27.648-28.16zM678.4 806.4V542.72c0-15.36 12.288-27.648 27.648-27.648 15.36 0 27.648 12.288 27.648 27.648v264.192c0 15.36-12.288 27.648-27.648 27.648-15.36-0.512-27.648-12.8-27.648-28.16zM809.984 806.4v-87.552c0-15.36 12.288-27.648 27.648-27.648 15.36 0 27.648 12.288 27.648 27.648v87.552c0 15.36-12.288 27.648-27.648 27.648-15.36 0-27.648-12.288-27.648-27.648zM284.672 807.424V329.728c0-14.848 11.776-26.624 26.624-26.624h2.048c14.848 0 26.624 11.776 26.624 26.624v477.696c0 14.848-11.776 26.624-26.624 26.624h-2.048c-14.848 0-26.624-11.776-26.624-26.624zM547.328 806.4V204.288c0-15.36 12.288-27.648 27.648-27.648 15.36 0 27.648 12.288 27.648 27.648v602.112c0 15.36-12.288 27.648-27.648 27.648-15.36 0-27.648-12.288-27.648-27.648zM415.744 806.4V443.904c0-15.36 12.288-27.648 27.648-27.648 15.36 0 27.648 12.288 27.648 27.648v362.496c0 15.36-12.288 27.648-27.648 27.648-15.36 0-27.648-12.288-27.648-27.648z"
                                        fill="#000000" p-id="2836"></path>
                                </svg>
                                <span slot="title" class="noSelect">{{$t('sidebar.systematicSupply')}}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="/WarrantyInquiry">
                                    <span slot="title" class="noSelect">{{$t('sidebar.warrantyInquiry')}}</span>
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index="4">
                            <template slot="title">
                                <svg t="1681715260981" class="Icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="8341" width="20" height="20">
                                    <path
                                        d="M502.568 533.137c-57.581 0-111.714-22.423-152.43-63.139C309.423 429.283 287 375.149 287 317.568s22.423-111.714 63.139-152.43C390.854 124.423 444.988 102 502.568 102s111.714 22.423 152.43 63.139c40.716 40.715 63.139 94.849 63.139 152.43s-22.423 111.714-63.139 152.43c-40.716 40.715-94.85 63.138-152.43 63.138z m0-367.137C418.993 166 351 233.993 351 317.568s67.993 151.568 151.568 151.568 151.568-67.993 151.568-151.568S586.144 166 502.568 166z"
                                        fill="#515151" p-id="8342"></path>
                                    <path
                                        d="M167.3 925.33c-15.61 0-29.274-11.436-31.618-27.339a375.592 375.592 0 0 1-3.985-54.58c0-50.136 9.826-98.788 29.206-144.607 18.712-44.239 45.492-83.964 79.598-118.069 34.106-34.106 73.831-60.887 118.07-79.599 45.819-19.38 94.472-29.206 144.607-29.206 96.311 0 187.621 36.704 257.107 103.35a374.562 374.562 0 0 1 21.778 22.726c11.68 13.264 10.396 33.483-2.868 45.163-13.263 11.682-33.484 10.396-45.163-2.868a311.092 311.092 0 0 0-18.048-18.831c-57.512-55.161-133.088-85.539-212.806-85.539-169.546 0-307.481 137.936-307.481 307.481 0 15.158 1.111 30.382 3.302 45.25 2.576 17.484-9.509 33.747-26.993 36.323a32.414 32.414 0 0 1-4.706 0.345z"
                                        fill="#515151" p-id="8343"></path>
                                    <path
                                        d="M576.112 926.072H171.607c-17.673 0-32-14.327-32-32s14.327-32 32-32h404.505c17.673 0 32 14.327 32 32s-14.327 32-32 32zM881.072 731.884H680.081c-17.673 0-32-14.327-32-32s14.327-32 32-32h200.991c17.673 0 32 14.327 32 32s-14.327 32-32 32zM882.072 830.884H681.081c-17.673 0-32-14.327-32-32s14.327-32 32-32h200.991c17.673 0 32 14.327 32 32s-14.327 32-32 32zM883.072 926.884H682.081c-17.673 0-32-14.327-32-32s14.327-32 32-32h200.991c17.673 0 32 14.327 32 32s-14.327 32-32 32z"
                                        fill="#515151" p-id="8344"></path>
                                </svg>
                                <span slot="title" class="noSelect">{{$t('sidebar.system')}}</span>
                            </template>
                            <el-menu-item-group>
                                <!-- <template slot="title">设备列表</template> -->
                                <el-menu-item index="/agent" v-if="!$store.state.permissions">
                                    <span slot="title" class="noSelect">{{$t('sidebar.agent')}}</span>
                                </el-menu-item>
                                <el-menu-item index="/personal">
                                    <span slot="title" class="noSelect">{{$t('sidebar.personal')}}</span>
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </el-menu>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import http from '@/utils/http'

export default {
    name: 'IndexComponent',
    data() {
        return {
            isColllapse: false,          //aside switch
            userName: '',
            userType: '',
            aside_width: '12%',
            logoWidth:'12%',
            menulist: {                  //breadcrumb navigation
                home: 'Home',
                Setting: '',
            },
            // permissions: 0,                  //用户权限
        }
    },
    
    methods: {
        handleCommand(command) {
            this.$router.go(0)
            // this.$i18n.locale=command=='zh'?'en':'zh'   //设置中英文模式
            this.$i18n.locale=command=='zh'?'zh':'en'   //设置中英文模式
            // console.log('切换语言',this.$i18n.locale);
            localStorage.setItem('languageSet',this.$i18n.locale)   //将用户设置存储到localStorage以便用户下次打开时使用此设置
        },
        // changeLanguage(){
        //     this.$router.go(0)
        //     this.$i18n.locale=='zh'?this.$i18n.locale='en':this.$i18n.locale='zh'   //设置中英文模式
        //     // console.log('切换语言',this.$i18n.locale);
        //     localStorage.setItem('languageSet',this.$i18n.locale)   //将用户设置存储到localStorage以便用户下次打开时使用此设置
        // },
        /**
         * Click toggle menu size
         */
        toggleAside() {
            this.isColllapse = (this.isColllapse == false ? true : false)
            this.aside_width = (this.isColllapse == false ? '12%' : '64px')
            this.logoWidth = (this.isColllapse == false ? '12%' : '64px')
        },
        /**
         * logout account
         */
        async logout() {
            let res=await http.post('/ops/agent/logOut',{email: this.$store.state.login.userName},true)
            // let res=await http.post('/ops/agent/logOut',{email: this.userName},true)
            if (res.data.result == 0) {
                    this.$router.push('/')
                }
        }

    },
    mounted() {
        
    },
}
</script>

<style lang="scss" scoped>
.noSelect {
    user-select: none;
}

#Index {

    .el-container {

        //导航栏
        .el-header {
            width: 100%;
            padding: 0;
            z-index: 99;
            background-color: #fff;
            box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
            display: flex;

            //logo
            .logo_box {
                // width: 12%;
                height: 8vh;
                background-color: #f9f9fb;
                border-right: solid 2px #f3f4f8;
                display: flex;
                align-items: center;

                .logo {
                    width: 40%;
                    height: 40%;
                    display: block;
                    margin: 0 auto;
                }

                .logob {
                    margin-left: 18px;
                    margin-right: 20px;
                    width: 25px;
                    height: 30px;
                }
            }

            .navigation_right {
                display: flex;
                // width: calc(100%-lo);
                justify-content: space-between;
                align-items: center;

                //面包屑导航栏
                .breadcrumb_box {
                    height: 8vh;
                    display: flex;
                    align-items: center;
                    margin-left: 20px;

                    .aside_btn {
                        font-size: 30px;
                        cursor: pointer;
                    }

                    .el-breadcrumb {
                        font-size: 16px;
                        line-height: 8vh;
                        margin-left: 20px;
                    }
                }

                .system_name {
                    font-weight: bolder;
                    font-size: 26px;
                    font-family: serif;
                }

                //头像
                .headImg {
                    width: 23%;
                    height: 100%;
                    display: flex;
                    line-height: 8vh;
                    text-align: center;
                    cursor: pointer;
                    background-color: #f9f9fb;
                    .languageSwitch{
                        width: 120px;
                        height: 7.8vh;
                        margin-right: 10px;
                        background-color: #f9f9fb;
                        border-right: solid 3px #a5a1a1 ;
                        border-top: solid 1px #f9f9fb ;
                        border-bottom: solid 1px #f9f9fb ;
                        border-left: solid 1px #f9f9fb ;
                    }
                    .el-dropdown {
                        .user {
                            display: flex;
                            align-items: center;

                            .userName {
                                margin-left: -15px;
                                font-size: 16px;
                                font-weight: 500;
                                color: #494a4d;
                            }
                        }
                    }
                }
            }
        }

        // 主体
        .el-container {

            // 侧边栏
            .el-aside {
                height: 92vh;
                background-color: #fbfaff;
                border-right: 1px solid #e6ebf5;
                z-index: 1;
                box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);

                .el-menu {
                    border: 0;
                    /* .el-menu-item{
                        &:focus{
                            background-color: #5e5485 !important;
                        }
                    } */
                }

                &:not(.el-menu--collapse) {
                    width: 10%;
                    min-height: 100%;
                }

                .homeIcon {
                    margin: 0 22px 0 2px;
                }

                .stationIcon {
                    margin: 0 23px 0 4px;
                }

                .ChargerIcon {
                    margin: 0 23px 0 1px;
                }

                .userIcon {
                    margin: 0 24px 0 0px;
                }

                .cardIcon {
                    margin: 0 22px 0 0px;
                }

                .connectorIcon {
                    margin: 0 22px 0 1px;
                }

                .groupIcon {
                    margin: 0 23px 0 0;
                }

                .alarmIcon {
                    margin: 0 25px 0 0;
                }

                .firmwareIcon {
                    margin: 0 25px 0 -1px;
                }

                .logsIcon {
                    margin: 0 23px 0 0;
                }
            }

            // 内容
            .el-main {
                width: 85%;
                height: 92vh;
                padding: 20px;
            }

            .Icon {
                margin: 0 25px 0 -1px;
            }
        }
    }
}</style>