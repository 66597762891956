export default{
    language: {
        name: 'English'
    },
    sidebar:{
        home:'首页',
        monitoring:'监控',
        endbenutzer:'终端用户列表',
        plant:'电站列表',
        datalog:'采集器列表',
        device:'设备列表',
        systematicSupply:'供货系统',
        warrantyInquiry:'质保查询',
        system:'系统',
        agent:'代理商列表',
        personal:'用户信息',
        logout:'退出账户',
    },
    home:{
        echarts1:{
            title:'七日新增用户',
            subhead:`今日注册/用户总数`
        },
        echarts2:{
            title:'七日登录用户',
            subhead:`在线设备/设备总数`
        },
        echarts3:{
            title:'七日登录代理商',
            subhead:`今日登录/代理商总数`
        },
        echarts4:{
            title:'七日新增电站',
            subhead:`新增电站/电站总数`
        },
        echarts5:{
            title:'七日新增采集器',
            subhead:`在线采集器/采集器总数`
        },
        echarts6:{
            title:'七日新增设备',
            subhead:`今日登录/用户总数`
        },
    },
    btn:{
        login:"登录",
        search:'搜索',
        edit:'编辑',
        enabled:'启用',
        forbidden:'禁用',
        cancle:'取消',
        submit:'提交',
        add:'添加',
        close:"关闭",
        set:'设置',
        setting:'设置',
        ready:'读取',
        delete:'删除',
        update:'修改',
        addDatalog:'添加采集器',
        info:'详情',
        historicalFault:'历史故障',
        importExcle:'导入表格',
        downloadTemplate:'下载模板',
        upload:'上传',
        export:'导出',
        yes:'是',
        no:'否',
    },
    form:{
        email:'邮箱',
        userName:'用户名',
        country:'国家',
        city:'城市',
        address:'地址',
        currency:'货币',
        timeZone:'时区',
        phone:'手机号',
        agent:'代理商',
        device:'设备',
        datalog:'采集器',
        plantId:'电站ID',
        plant:'电站',
        plantName:'电站名称',
        plantType:"电站类型",
        plantStatus:'电站状态',
        plantImage:'电站图片',
        alias:'别名',
        datalogSn:'采集器序列号',
        installationDate:'安装日期',
        companyName:'公司名称',
        pwd:'密码',
        oldpwd:'旧密码',
        newpwd:'新密码',
        confirmPwd:'确认密码',
        advancedSetup:'高级设置',
        advancedRead:'高级读取',
        agentType:'代理商',
        createTime:'创建时间',
        lastLoginTime:'最后一次登录时间',
    },
    dialog:{
        editEndbenutzer:"修改终端用户",
        editPlant:'修改电站',
        editDatalog:'修改采集器',
        editAgent:'修改代理商',
        editUserPwd:'修改用户密码',
        addDatalog:'添加采集器',
        plantDetails:"电站详情",
        setDatalog:'设置采集器',
        mgrnInfo:'MGRN 设备详情',
        pcsInfo:'PCS 设备详情',
        xpInfo:'XP 设备详情',
        hvboxInfo:'HVBOX 设备详情',
        agentInfo:'代理商详情',
        addAgent:'添加代理商',
        importExcle:'导入表格',
        deleteMsg:"你确定要删除这个{name}吗?",
    },
    verify:{
        noAllowSpaces:'{name}不允许包含空格',
        userRight:"请输入一个正确的邮箱",
        back:'欢迎回来 !',
        loginMsg:'登录以继续 SHOUXD.',
        noEmpty:`{params}不能为空`,
        comparePwd:'两次输入的密码不一致',
        rememberAccount:"记住账号密码",
        importTemplate:'导入表格模板',
        download:'卸载表格模板',
        selectFile:'请选择表格文件导入',
        condition:'只能上传xls/xlsx文件，且不超过10M',
    },
    placeholder:{
        msg:'请输入{name}',
        mode:"设备型号",
        orderNumLike:'订单编号',
        qualityTime:'质保时间',
        serialNumLike:'序列号',
        deviceSn:'设备序列号',
        date:'日期',
        setCommandId:"设置命令序号",
        setParam:'设置参数',
        readCommandId:'读取命令序号'
    }
}