import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'default-passive-events'
import i18n from './Language/index'


// import MD5
import md5 from 'js-md5'
// import ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import echarts
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false    // 生产提示
Vue.prototype.$md5 = md5

Vue.use(ElementUI,{
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
