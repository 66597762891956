export default{
    language: {
        name: '中文'
    },
    sidebar:{
        home:'Home',
        monitoring:'Monitoring',
        endbenutzer:'Endbenutzer',
        plant:'Plant',
        datalog:'Datalog',
        device:'Device',
        systematicSupply:'Supply System',
        warrantyInquiry:'Warranty inquiry',
        system:'System',
        agent:'Agent',
        personal:'Userinfo',
        logout:'Logout',
    },
    home:{
        echarts1:{
            title:'Seven-day new user',
            subhead:`Today's registration/Total number of users`
        },
        echarts2:{
            title:'Seven-day login user',
            subhead:`Today's login/Total number of users`
        },
        echarts3:{
            title:'Seven-day registration agent',
            subhead:`Today's login/Total number of agents`
        },
        echarts4:{
            title:'Seven-day new power station',
            subhead:`The new power station/Total power station`
        },
        echarts5:{
            title:'New collector added seven days ago',
            subhead:`Online collector/Total collector`
        },
        echarts6:{
            title:'Seven-day new equipment',
            subhead:`Online device/Total device`
        },
    },
    btn:{
        login:'Login',
        search:'Search',
        edit:'Edit',
        enabled:'Enabled',
        forbidden:'Forbidden',
        cancle:'Cancle',
        submit:'Submit',
        add:'Add',
        close:"Close",
        set:'Set',
        setting:'Setting',
        ready:'Ready',
        delete:'Delete',
        update:'Update',
        addDatalog:'Add Datalog',
        info:'Info',
        historicalFault:'Historical Fault',
        importExcle:'Import Excle',
        downloadTemplate:'Download Template',
        upload:'Upload',
        export:'Export',
        yes:'Yes',
        no:'No',
    },
    form:{
        email:'Email',
        userName:'UserName',
        country:'Country',
        city:'City',
        address:'Address',
        currency:'Currency',
        timeZone:'TimeZone',
        phone:'Phone',
        agent:'Agent',
        device:'Device',
        datalog:'Datalog',
        plant:'Plant',
        plantId:'Plant ID',
        plantName:'Plant Name',
        plantType:"Plant Type",
        plantStatus:"Plant Status",
        plantImage:'Plant Image',
        alias:'Alias',
        datalogSn:'Datalog SN',
        installationDate:'Installation Date',
        companyName:'Company Name',
        pwd:'Password',
        oldpwd:'Old Password',
        newpwd:'New Password',
        confirmPwd:'Confirm Password',
        advancedSetup:'Advanced Setup',
        advancedRead:'Advanced Ready',
        agentType:'Agent Type',
        createTime:'Create Time',
        lastLoginTime:'Last Login Time',
        setParamId:'param'
    },
    dialog:{
        editEndbenutzer:"Update Endbenutzer",
        editPlant:'Update Plant',
        editDatalog:'Update Datalog',
        editAgent:'Update Agent',
        editUserPwd:'Update User Password',
        addDatalog:'Add Datalog',
        plantDetails:"Plant Details",
        setDatalog:'Set Datalog',
        mgrnInfo:'MGRN Device Details',
        pcsInfo:'PCS Device Details',
        xpInfo:'XP Device Details',
        hvboxInfo:'HVBOX Device Details',
        agentInfo:'Agent Device Details',
        addAgent:'Add Agent',
        importExcle:'Import Excle',
        deleteMsg:"Are you sure to delete this {name}?",
    },
    verify:{
        noAllowSpaces:'The {name} does not allow Spaces',
        userRight:"Please enter correct Email",
        back:'Welcome Back !',
        loginMsg:'Sign in to continue to SHOUXD.',
        noEmpty:`The {params} cannot be empty`,
        comparePwd:'Entered passwords differ',
        rememberAccount:"Remember Account and passeord",
        importTemplate:'Import the table file template',
        download:'Download Template Excle',
        selectFile:'Please Select Excle File To import',
        condition:'Only xls/xlsx files can be uploaded, and the size does not exceed 10 MB',
    },
    placeholder:{
        msg:'Please enter the {name}',
        mode:"mode",
        orderNumLike:'orderNumLike',
        qualityTime:'qualityTime',
        serialNumLike:'serialNumLike',
        deviceSn:'Device SN',
        date:'Date',
        setCommandId:"Id of the setting command",
        setParam:'set param',
        readCommandId:'read command Id'
    }
}