import axios from 'axios';
import qs from 'qs'

//创建axios实例
const service=axios.create({
    baseURL:"xxx",
    timeout:600000,
    withCredentials:true,
    // crossDomain:true,
    headers:{
        'Cache-Control': 'no-cache',
        'strict-origin-when-cross-origin':'*',
        'Accept-Language':localStorage.getItem('languageSet'),
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',              //请求Form data  字符串
            // 'Content-Type': 'application/json;charset=utf-8'                             //请求Request payload    JSON对象
        }
    },
    // 对发送的 data 进行任意转换处理
    transformRequest:[function (data, headers) {
        // 通过qs对请求数据进行序列化
        return qs.stringify(data);
    }],
    // 浏览器将要响应的数据类型
    responseType: 'json',   
})

//request   请求拦截器
service.interceptors.request.use(request=>{
    // console.log('Request=>',request);
    return Promise.resolve(request)
},err=>{
    // console.log('Request Error:',err);
    return Promise.reject(err)
})

//response  响应拦截器
service.interceptors.response.use(response=>{
    // console.log('Response=>',response);
    return Promise.resolve(response)
},err=>{
    // console.log('Reponse Error:',err);
    return Promise.reject(err)
})

export default service;