import router from "@/router";
import request from "./request"
import { Message } from "element-ui"

export default {
    // showToast:true,
    toast:{
        success:'Request Success',
        fail:'Request Fail'
    },
    /**
     * 响应成功提示
     * @param {*} res 
     */
    success(res,showToast,toast){
        if(res.data.result==0){
            // console.log('success res',res);
            if(showToast) toast? Message.success(toast.success):Message.success(res.data.msg)
        }else if(res.data.result==10000){
            // console.log('Cookie Expire res',res);
            //cookie过期，需要重新登录
            toast? Message.error(toast.fail):Message.error(res.data.msg)
            router.push('/')
        }else{
            // console.log('Fail res',res);
            toast? Message.error(toast.fail):Message.error(res.data.msg)
        }
    },
    /**
     * 错误信息提示
     * @param {*} err 
     */
    errMessage(err){
        console.log('Promise Reject Error',err);
        if(err.code=="ECONNABORTED"){
            Message.error('Server error, please contact the administrator')
        }else if(err.request.status==405){
            Message.error('Server error, please contact the administrator')
        }{  
            Message.error(err.message)
        }
    },
    /**
     * POST 请求
     * @param {String} url                  请求地址
     * @param {Object} data                 请求体
     * @param {Boolean} showToast           是否显示提示信息
     * @param {Object} toast                提示信息
     * @returns 
     */
    post(url,data,showToast,toast){
        return new Promise((resolve,reject)=>{
            request.post(url,data).then(res=>{
                this.success(res,showToast,toast)
                return resolve(res)
            }).catch(err=>{
                this.errMessage(err)
                return reject(err)
            })
        })
    },
    get(url,params,showToast,toast){
        return new Promise((resolve,reject)=>{
            request.get(url,params).then(res=>{
                this.success(res,showToast,toast)
                return resolve(res)
            }).catch(err=>{
                this.errMessage(err)
                return reject(err)
            })
        })
    },
    put(url,data,showToast,toast){
        return new Promise((resolve,reject)=>{
            request.put(url,data).then(res=>{
                this.success(res,showToast,toast)
                return resolve(res)
            }).catch(err=>{
                this.errMessage(err)
                return reject(err)
            })
        })
    },
    delete(url,params,showToast,toast){
        return new Promise((resolve,reject)=>{
            request.delete(url,params).then(res=>{
                this.success(res,showToast,toast)
                return resolve(res)
            }).catch(err=>{
                this.errMessage(err)
                return reject(err)
            })
        })
    }
}
