import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login:{
      userName:'',
      pwd:'',
    },
    remember:false,                       //密码是否记住
    loginTimes:0,                         //登陆次数
    permissions:'',                       //用户权限
  },
  getters: {
  },
  mutations: {
    /**
     * 修改用户名
     * @param {*} state 
     * @param {*} name 
     */
    editUserName(state,name){
      state.login.userName=name
    },
    /**
     * 修改密码
     * @param {*} state 
     * @param {*} pwd 
     */
    editPwd(state,pwd){
      state.login.pwd=pwd
    },
    /**
     * 修改登陆次数
     * @param {*} state 
     * @param {*} num 
     */
    editLoginTimes(state,num){
      state.loginTimes=num
    },
    /**
     * 修改密码记住状态
     * @param {*} state 
     * @param {*} status 
     */
    editRemember(state,status){
      state.remember=status
    },
    /**
     * 修改用户权限
     * @param {*} state 
     * @param {*} angentType 
     */
    editPermissions(state,userPermission){
      state.permissions=userPermission
    },
  },
  actions: {
  },
  modules: {
  }
})
