<template>
    <div id="app">
        <div class="login">
            <div class="login_box" style="margin: 0 auto;">
                <div class="logobg" style="margin-top: -300px;">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"
                        class="logo" style="margin-right: 5px;">
                        <path fill="#c1c0ff"
                            d="M12,14.19531c-0.17551-0.00004-0.34793-0.04618-0.5-0.13379l-9-5.19726C2.02161,8.58794,1.85779,7.97612,2.13411,7.49773C2.22187,7.34579,2.34806,7.2196,2.5,7.13184l9-5.19336c0.30964-0.17774,0.69036-0.17774,1,0l9,5.19336c0.4784,0.27632,0.64221,0.88814,0.36589,1.36653C21.77813,8.65031,21.65194,8.7765,21.5,8.86426l-9,5.19726C12.34793,14.14913,12.17551,14.19527,12,14.19531z" />
                        <path fill="#a2a1ff"
                            d="M21.5,11.13184l-1.96411-1.13337L12.5,14.06152c-0.30947,0.17839-0.69053,0.17839-1,0L4.46411,9.99847L2.5,11.13184c-0.47839,0.27632-0.64221,0.88814-0.36589,1.36653C2.22187,12.65031,2.34806,12.7765,2.5,12.86426l9,5.19726c0.30947,0.17838,0.69053,0.17838,1,0l9-5.19726c0.4784-0.27632,0.64221-0.88814,0.36589-1.36653C21.77813,11.34579,21.65194,11.2196,21.5,11.13184z" />
                        <path fill="#6563ff"
                            d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152c-0.30947,0.17838-0.69053,0.17838-1,0l-7.03589-4.06305L2.5,15.13184c-0.47839,0.27632-0.64221,0.88814-0.36589,1.36653C2.22187,16.65031,2.34806,16.7765,2.5,16.86426l9,5.19726c0.30947,0.17838,0.69053,0.17838,1,0l9-5.19726c0.4784-0.27632,0.64221-0.88814,0.36589-1.36653C21.77813,15.34579,21.65194,15.2196,21.5,15.13184z" />
                    </svg>
                    <span class="logo_txt">SHOUXD</span>
                </div>
                <div>
                    <span class="login_title" style="font-size: 20px;">{{ $t('verify.back') }}</span>
                    <span style="font-size: 15px;">{{ $t('verify.loginMsg') }}</span>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm login_form" style="margin-top: 30px;">
                    <el-form-item prop="userName" :label="$t('form.userName')">
                        <el-input v-model="ruleForm.userName" clearable prefix-icon="el-icon-user-solid" autocomplete="off"
                            placeholder="User Name" class="input_size"></el-input>
                    </el-form-item>
                    <el-form-item prop="checkPass" :label="$t('form.pwd')">
                        <el-input type="password" v-model="ruleForm.checkPass" clearable prefix-icon="el-icon-lock"
                            show-password placeholder="Password" class="input_size" autocomplete="off" @focus="ruleForm.checkPass=''"></el-input>
                    </el-form-item>
                    <el-checkbox v-model="remember" :checked="remember" class="checkRemember">{{$t('verify.rememberAccount')}}</el-checkbox>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" class="input_btn" size="small">{{$t('btn.login')}}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="login_bg">
                <div class="mask_layer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/utils/http';
import { mapMutations } from 'vuex';
export default {
    data() {
        var checkName = (rule,value, callback) => {
            let email=/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
            if (value=='') {
                callback(new Error(this.$t('verify.noEmpty',{params:this.$t('form.userName')})));
            }else if(!/^\S*$/.test(value)){
                callback(new Error(this.$t('verify.noAllowSpaces',{params:this.$t('form.userName')})));
            }else if(!email.test(value)){
                callback(new Error(this.$t('verify.userRight')));
            }else{
                callback();
            }
        };
        var validatePass = (rule,value, callback) => {
            if (value=='') {
                callback(new Error(this.$t('verify.noEmpty',{params:this.$t('form.pwd')})));
            }else if(!/^\S*$/.test(value)){
                callback(new Error(this.$t('verify.noAllowSpaces',{params:this.$t('form.pwd')})));
            }else{
                callback();
            }
        };
        return {
            ruleForm: {
                userName: '',
                checkPass: '',
                // userName: '2671236324@qq.com',
                // checkPass: '123456',
            },
            rules: {
                userName: [
                    { validator: checkName, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            },
            remember: false,                        //是否记住密码
            loginTimes:0,                           //记住密码后登录次数
        }
    },
    created() {
        this.remember=!!this.$store.state.remember
        this.loginTimes=this.$store.state.loginTimes
        if(this.remember){
            this.ruleForm.userName=this.$store.state.login.userName
            this.ruleForm.checkPass=this.$store.state.login.pwd
        }else{
            this.ruleForm.userName=''
            this.ruleForm.checkPass=''
        }
    },
    methods: {
        ...mapMutations(['editUserName','editPwd','editLoginTimes','editRemember','editPermissions']),
        /**
         * 验证表单
         * @param {*} formName 
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.login();
                } else {
                    return false;
                }
            });
        },
        /**
         * login
         */
        async login() {
            let pwd
            if (!this.loginTimes) {
                pwd = this.$md5(this.ruleForm.checkPass)
            } else if (this.loginTimes > 0) {
                //如果登录次数大于0，如果密码不等于32位，说明密码改动了，就重新加密;如果密码是加密过后的，直接赋值登录
                if(this.ruleForm.checkPass.length!=32){
                    pwd = this.$md5(this.ruleForm.checkPass)
                }else{
                    pwd = this.ruleForm.checkPass
                }
            }
            let res = await http.post('/ops/agent/login', {
                email: this.ruleForm.userName,
                password: pwd
            },true)
            if (res.data.result == 0) {
                this.$store.state.userName = this.ruleForm.userName
                this.editUserName(this.ruleForm.userName)
                if (this.remember) {
                    if (!this.loginTimes) {
                        this.editPwd(this.$md5(this.ruleForm.checkPass))
                    } else {
                        this.editPwd(this.$store.state.login.pwd)
                    }
                    this.loginTimes++
                    this.editLoginTimes(this.loginTimes)
                } else {
                    this.editPwd('')
                    this.editLoginTimes(0)
                }
                this.editRemember(this.remember)
                this.editPermissions(res.data.obj.agentType)
                this.$router.push({ path: "/home" });
            }
        }
    },
}

</script>

<style lang="scss" scoped>
#app {

    width: 100%;
    height: 100vh;

    .login {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;

        .login_bg {
            width: 85%;
            height: 100%;
            background: url('@/assets/img/auth-bg.jpg') no-repeat;
            background-size: cover;

            .mask_layer {
                width: 100%;
                height: 100%;
                background-color: #585bad;
                opacity: 0.7;
            }
        }

        .login_box {
            width: 30%;
            height: 100%;
            display: flex;  
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .logobg {
                width: 100px;
                height: 100px;
                padding-bottom: 100px;
                margin: 0 auto;

                .logo {
                    width: 30px;
                    height: 30px;
                    vertical-align: middle
                }

                .logo_txt {
                    vertical-align: middle;
                    font-size: 20px;
                }
            }

            .login_title {
                display: block;
                width: 100%;
                // height: 100%;
                margin-bottom: 20px;
                font-size: 20px;
                font-weight: bold;
            }

            .login_form {
                width: 60%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;

                .input_size {
                    width: 100%;
                }

                .checkRemember {
                    text-align: left;
                    margin-bottom: 10px;
                }

                .input_btn {
                    border: none;
                    background-color: #7074db;
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }
}
</style>