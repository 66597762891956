<template>
  <div id="app">
    <IndexComponent v-if="this.$route.path!=='/'"></IndexComponent>
    <router-view v-else-if="this.$route.path=='/'"></router-view>
  </div>
</template>

<script>
import IndexComponent from './components/IndexComponent.vue'
export default {
  components: { IndexComponent },
  created() {
    //保持vuex数据持久化（再刷新清楚vuex的同时在sessionstorage中存一份数据，然后赋给state，然后删除缓存中数据）
    window.addEventListener('beforeunload',()=>{
      sessionStorage.setItem('list', JSON.stringify(this.$store.state))
    })
    
    try{
      sessionStorage.getItem('list') && this.$store.replaceState(Object.assign({},this.$store.state,JSON.parse(sessionStorage.getItem('list'))))
    }catch(err) {
      console.log(err);
    }
  
    sessionStorage.removeItem("list");
  }

}

</script>
<style lang="scss">
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
</style>
