import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  // login
  {
    path: '/',
    name: 'login', 
    meta: {
      isAuth:true,
      title:'login',
    },
    component: LoginView
  },
  // index
  {
    path: '/home',
    name: 'home',
    meta: {
      title:'Home',
    },
    component: () => import( '../views/HomeView.vue')
  },
  // 终端用户
  {
    path: '/endbenutzer',
    name: 'endbenutzer',
    meta: {
      title:'终端用户',
    },
    component: () => import( '../views/Monitoring/EndbenutzerView.vue')
  },
  //设备
  {
    path: '/device',
    name: 'device',
    meta: {
      title:'设备列表',
    },
    component: () => import( '../views/Monitoring/DeviceView')
  },
  //采集器列表
  {
    path: '/datalog',
    name: 'datalog',
    meta: {
      title:'采集器列表',
    },
    component: () => import( '../views/Monitoring/DatalogView.vue')
  },
  //电站列表
  {
    path: '/plant',
    name: 'plant',
    meta: {
      title:'电站列表',
    },
    component: () => import( '../views/Monitoring/PlantView.vue')
  },
  // 用户中心
  {
    path: '/agent',
    name: 'agent',
    meta: {
      title:'用户中心',
    },
    component: () => import( '../views/System/Agent.vue')
  },
  // 用户中心
  {
    path: '/personal',
    name: 'personal',
    meta: {
      title:'用户信息',
    },
    component: () => import( '../views/System/PersonalInformation.vue')
  },
  // 质保查询
  {
    path: '/WarrantyInquiry',
    name: 'warrantyInquiry',
    meta: {
      title:'质保查询',
    },
    component: () => import( '../views/SystematicSupply/WarrantyInquiry.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    meta: {
      title:'NotFound',
    },
    component: () => import( '../views/NotFound.vue')
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//全局前置路由，配合浏览器localStorage进行鉴权操作
router.beforeEach((to, from, next) => {
  //首先，我们先看一看to和from参数，next就是执行的意思，不写页面是不会跳转的
  // console.log('cookie sessionID', Cookies);
  document.title = to.meta.title
  // if (to.meta.isAuth) { //判断是否需要授权
  //   if (localStorage.getItem('school') === 'qinghuadaxue') {
  //     next()  //放行
  //   } else {
  //     alert('抱歉，您无权限查看！')
  //   }
  // } else {
    next()  //放行
  // }
})
export default router
