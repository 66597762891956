import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from 'element-ui/lib/locale/lang/en'
import Zh_CN from 'element-ui/lib/locale/lang/zh-CN'
import ENLocale from './EN'
import ZH_CNLocale from './Zh_CN'

Vue.use(VueI18n)
const messages = {
    zh: {
        ...ZH_CNLocale,
        ...Zh_CN
    },
    en: {
        ...ENLocale,
        ...EN
    }
}

const i18n = new VueI18n({
    locale: localStorage.getItem('languageSet') || 'zh', // 设置语种
    messages, // 设置全局当地语言包,
    fallbackLocale: 'zh', //如果当前语种不存在时，默认设置当前语种
})

// router.beforeEach((to, from, next) => {
//     i18n.t(`router.${to.name}`)
//     next()
// })

export default i18n